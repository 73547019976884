import isAdminGuard, { isHrGuard } from "./roles-guards";
import centerRouter from "./center-router";
import profileRouter from "./profile-router";
import insuranceCenterRoute from "./insurance-center-route";
import i18n from "@/plugins/i18n";

const { t } = i18n.global;

export default {
  name: "axident-admin",
  beforeEnter: [isAdminGuard],
  component: () =>
    import(
      /* webpackChunkName: "admin" */ "@/modules/axident/layouts/AxidentAdminLayout.vue"
    ),
  children: [
    {
      path: "",
      name: "axident-admin-2",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR", "ROL_ADMIN_PRL"],
        breadCrumb: t("breadCrumbs.admin"),
      },
      beforeEnter: [isAdminGuard],
      component: () =>
        import(
          /* webpackChunkName: "admin" */ "@/modules/axident/views/AxidentAdminMenu.vue"
        ),
    },
    {
      path: "profiles",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR", "ROL_ADMIN_PRL"],
        breadCrumb: t("breadCrumbs.profiles"),
      },
      beforeEnter: [isAdminGuard],
      ...profileRouter,
    },
    {
      path: "centers",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR"],
        breadCrumb: t("breadCrumbs.centerAndZones"),
      },
      beforeEnter: [isHrGuard],
      ...centerRouter,
    },
    {
      path: "insurance-center",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR"],
        breadCrumb: t("breadCrumbs.insuranceCenter"),
      },
      beforeEnter: [isHrGuard],
      ...insuranceCenterRoute,
    },
    {
      path: "create-center",
      name: "create-center",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR"],
        breadCrumb: t("breadCrumbs.addCenterAndZones"),
      },
      beforeEnter: [isHrGuard],
      component: () =>
        import(
          /* webpackChunkName: "create-center" */ "@/modules/axident/views/AxidentCreateCenterAndZone.vue"
        ),
    },
    {
      path: "job-roles",
      name: "roles",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR", "ROL_ADMIN_PRL"],
        breadCrumb: t("breadCrumbs.jobs"),
      },
      beforeEnter: [isAdminGuard],
      component: () =>
        import(
          /* webpackChunkName: "job-roles" */ "@/modules/axident/views/AxidentAdminJobs.vue"
        ),
    },
    {
      path: "accident-management",
      name: "accident-management",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR"],
        breadCrumb: t("breadCrumbs.accidentManagement"),
      },
      beforeEnter: [isHrGuard],
      component: () =>
        import(
          /* webpackChunkName: "sap-connection" */ "@/modules/axident/views/AccidentManagement.vue"
        ),
    },
  ],
};
