import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import VCalendar from "v-calendar";
import { clickOutside } from "./plugins/directives";
import { defineRule } from "vee-validate";
import "../node_modules/nprogress/nprogress.css";

import {
  required,
  decathlonEmail,
  phoneNumber,
  siteEmail,
  fax,
  email,
  numberText,
} from "./plugins/vee-validate";

// vee-validate validations
defineRule("required", required);
defineRule("decathlonEmail", decathlonEmail);
defineRule("phoneNumber", phoneNumber);
defineRule("siteEmail", siteEmail);
defineRule("fax", fax);
defineRule("email", email);
defineRule("numberText", numberText);

// Css
import "./assets/scss/styles.scss";
import "v-calendar/style.css";

// vtmn
import "@vtmn/css/dist/index.css";
import "@vtmn/vue/dist/style.css";
import "@vtmn/css-design-tokens/dist/index.css";
import "@vtmn/icons/dist/vitamix/font/vitamix.css";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(i18n);
app.use(VCalendar);
app.directive("debounce", {
  beforeMount(el, binding) {
    // eslint-disable-next-line no-undef
    el.oninput = debounce(function () {
      el.dispatchEvent(new Event("debounce-input"));
    }, binding.value || 300);
  },
});
app.directive("click-outside", clickOutside);
app.mount("#app");
