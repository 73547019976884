import { createI18n } from "vue-i18n";
import messages from "../locale/messages";

const getLocale = () => {
  if (localStorage.getItem("locale")) {
    return localStorage.getItem("locale");
  } else {
    return "es-ES";
  }
};

// create instance of I18n which is exported and call it globally on main.js with the messages
const i18n = createI18n({
  legacy: false,
  locale: getLocale(),
  fallbackLocale: "es-ES",
  messages,
});

export default i18n;
